import { template as template_3cb373798c904e158aa1d6ab48107719 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_3cb373798c904e158aa1d6ab48107719(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
