import { template as template_ddc3484104dc4c569e747609e9ed6298 } from "@ember/template-compiler";
const WelcomeHeader = template_ddc3484104dc4c569e747609e9ed6298(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
