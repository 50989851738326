import { template as template_90ae7560f67d44f3821f33ee0012e82f } from "@ember/template-compiler";
import { fn } from "@ember/helper";
import { or } from "truth-helpers";
import DButton from "discourse/components/d-button";
const ShareSource = template_90ae7560f67d44f3821f33ee0012e82f(`
  <DButton
    @action={{fn @action @source}}
    @translatedTitle={{@source.title}}
    @icon={{or @source.icon @source.htmlIcon}}
    class="btn-default share-{{@source.id}}"
    ...attributes
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ShareSource;
